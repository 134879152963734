import React from 'react';

function HistoryArea() {
  return (
    <>
     <section className="history-area sec-mar">
        <div className="container">
          <div className="title-wrap">
            <div className="sec-title">
              <span>History</span>
              <h2>Our Journey</h2>
            </div>
          </div>
          <div className="single-history">
            <div className="history wow animate fadeInLeft" data-wow-delay="200ms" data-wow-duration="1500ms">
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img src={process.env.PUBLIC_URL + '/img/dl3.jpg'} alt="Opening Office" />
              </div>
            </div>
            <div className="history wow animate fadeInRight" data-wow-delay="200ms" data-wow-duration="1500ms">
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-cnt">
                <div className="history-cnt-inner">
                  <span>2019</span>
                  <h4>Product Launch</h4>
                  <p>In 2019, we embarked on an exciting journey by launching multiple innovative products. Our flagship product, <strong>dlupload.com</strong>, revolutionized cloud storage solutions, amassing over 350,000 users worldwide. We empowered small businesses by creating robust websites, hosting services, e-commerce platforms, and management software, laying the foundation for our dynamic growth.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="single-history">
            <div className="history wow animate fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
              <div className="circle">
                <div className="inner" />
              </div>
<div className="history-cnt">
  <div className="history-cnt-inner">
    <span>2021</span>
    <h4>Team Expansion</h4>
<p>The year 2021 marked a pivotal turning point in our journey. We significantly expanded our team, welcoming a diverse group of talented professionals from various backgrounds and expertise. With a larger team, we were able to take on more ambitious projects, offer unparalleled tech solutions, and make a greater impact in the tech industry. This expansion also allowed us to better serve our clients, providing them with a wider range of solutions and a higher level of service. As we reflect on this milestone, we are proud of the growth we achieved and are excited about the opportunities that lie ahead.</p>             </div>
              </div>
            </div>
            <div className="history wow animate fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms">
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img src={process.env.PUBLIC_URL + '/img/hero-banner.jpg'} alt="Design Innovation" />
              </div>
            </div>
          </div>
          <div className="single-history">
            <div className="history wow animate fadeInLeft" data-wow-delay="400ms" data-wow-duration="1500ms">
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img src={process.env.PUBLIC_URL + '/img/timeline-3.jpg'} alt="Achieving Success" />
              </div>
            </div>
            <div className="history wow animate fadeInRight" data-wow-delay="400ms" data-wow-duration="1500ms">
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-cnt">
  <div className="history-cnt-inner">
    <span>2022</span>
    <h4>Expanding to Australia</h4>
    <p>In 2022, we made a significant leap by launching our services in Australia. This strategic expansion diversified our global footprint and attracted a broad spectrum of clients from various industries. Our unwavering commitment to delivering top-notch tech solutions earned us recognition and solidified our reputation as a trusted tech partner in the Australian market.</p>
  </div>
</div>
            </div>
          </div>
          <div className="single-history">
            <div className="history wow animate fadeInLeft" data-wow-delay="500ms" data-wow-duration="1500ms">
              <div className="circle">
                <div className="inner" />
              </div>
             <div className="history-cnt">
  <div className="history-cnt-inner">
    <span>2024</span>
    <h4>Gaining Recognition</h4>
    <p>In 2024, a landmark year for our company, we saw our relentless pursuit of innovation and excellence celebrated with prestigious accolades. Our team's hard work and dedication were recognized on an international scale, marking a significant milestone in our journey.</p>
    <p>We were honored with the 'Most Innovative Cloud Solution' award by APAC Insider, a testament to our commitment to pushing the boundaries of what's possible in the cloud computing space. This award recognized our innovative approach to cloud solutions, highlighting our ability to leverage cutting-edge technologies to deliver scalable, secure, and cost-effective solutions for our clients.</p>
    <p>In the same year, we also secured a position among the top 3 winners at the GSEA Nepal. This achievement was a reflection of our entrepreneurial spirit and our commitment to creating impactful tech solutions. Competing against a host of talented entrepreneurs, our placement in the top 3 was a proud moment for our entire team.</p>
  </div>
</div>
            </div>
            <div className="history wow animate fadeInRight" data-wow-delay="500ms" data-wow-duration="1500ms">
              <div className="circle">
                <div className="inner" />
              </div>
              <div className="history-thumb">
                <img src={process.env.PUBLIC_URL + '/img/timeline-4.jpg'} alt="Award Winning" />
              </div>
            </div>
          </div>
        </div>
      </section>   
    </>
  )
}

export default HistoryArea;
