import React, { useEffect, useReducer, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import WOW from "wowjs";

function Header() {
  const [sidebar, setSidebar] = useState(false);
  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    // Initialize WOW.js for animations
    new WOW.WOW().init();

    const handleScroll = () => {
      if (window.scrollY > ref.current.clientHeight) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSidebarMenu = () => {
    setSidebar(!sidebar);
  };

  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const initialState = { activeMenu: "" };
  const [state, dispatch] = useReducer(reducer, initialState);

  function reducer(state, action) {
    switch (action.type) {
      case "homeOne":
        return { activeMenu: "homeOne" };
      case "service":
        return { activeMenu: "service" };
      case "project":
        return { activeMenu: "project" };
      case "blog":
        return { activeMenu: "blog" };
      case "page":
        return { activeMenu: "page" };
      default:
        return { activeMenu: "" };
    }
  }

  return (
    <>
      <header
        className={`header-area position_top ${isSticky ? "sticky" : ""}`}
        ref={ref}
      >
        <div className="site-logo">
          <div className="">
            <Link to={"/"} onClick={scrollTop}>
              <img
              width={200}
                src={process.env.PUBLIC_URL + "/img/logo.png"}
                alt="logo-img"
              />
            </Link>
          </div>
        </div>
        <div className="main-menu">
          <nav className={sidebar ? "main-nav slidenav" : "main-nav"}>
            <div className="mobile-menu-logo">
              <Link to={"/"} onClick={scrollTop}>
                <img
                  src={process.env.PUBLIC_URL + "img/logo-dark.png"}
                  alt="images"
                />
              </Link>
              <div className="remove" onClick={handleSidebarMenu}>
                <i className="bi bi-plus-lg" />
              </div>
            </div>
            <ul>
              <li
                className={`has-child ${state.activeMenu === "homeOne" ? "active" : ""}`}
                onClick={() => dispatch({ type: "homeOne" })}
              >
                <Link to={`${process.env.PUBLIC_URL}/`} onClick={scrollTop}>
                  Home
                </Link>
              </li>
              <li>
                <NavLink to={`${process.env.PUBLIC_URL}/about-us`} onClick={scrollTop}>
                  About Us
                </NavLink>
              </li>
              <li>
                <NavLink to={`${process.env.PUBLIC_URL}/services`} onClick={scrollTop}>
                  Services
                </NavLink>
              </li>
              <li>
                <NavLink to={`${process.env.PUBLIC_URL}/projects`} onClick={scrollTop}>
                  Projects
                </NavLink>
              </li>
              <li>
                <NavLink to={`${process.env.PUBLIC_URL}/contact-us`} onClick={scrollTop}>
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
        <div className="nav-right">
          <div className="get-qoute">
            <div className="cmn-btn">
              <div className="cmn-btn layout-two">
                <div className="line-1" />
                <div className="line-2" />
               <a href="https://calendly.com/dlplatforms/30min/" target="_blank" rel="noopener noreferrer" onClick={scrollTop}>
  Book A Call
</a>
              </div>
            </div>
          </div>
          <div className="mobile-menu">
            <Link to="#" onClick={handleSidebarMenu} className={"cross-btn"}>
              <span className="cross-top" />
              <span className="cross-middle" />
              <span className="cross-bottom" />
            </Link>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
