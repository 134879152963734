import React from 'react';
import { Link } from 'react-router-dom';
import "swiper/css/autoplay";
// Import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, EffectFade, Pagination } from "swiper";
SwiperCore.use([Navigation, Autoplay, EffectFade, Pagination]);

function Testimonial() {
    const testimonialSlider = {
        loop: true,
        slidesPerView: 2,
        centeredSlides: true,
        duration: 5000,
        speed: 1000,
        autoplay: {
          delay: 3500,
          disableOnInteraction: true,
        },
        spaceBetween: 30,
        slideToClickedSlide: true,

        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            576: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 2,
            },
            1400: {
                slidesPerView: 2,
            },
            1800: {
                slidesPerView: 2,
            }
        }
    };

    return (
        <>
            <section className="testimonial-area">
                <div className="container-fluid p-0">
                    <div className="title-wrap">
                        <div className="sec-title white">
                            <span>Testimonial</span>
                            <h2>What Our Clients Say</h2>
                            <p>Discover the experiences of our clients who have partnered with us for exceptional results.</p>
                        </div>
                    </div>
                    <Swiper {...testimonialSlider} className="swiper testimonial-slider">
                        <div className="swiper-wrapper">
                            <SwiperSlide className="swiper-slide">
                                <div className="single-testimonial">
                                    <div className="quote">
                                        <i className="fas fa-quote-right" />
                                    </div>
                                    <h5>Raju Joshi</h5>
                                    {/* <span>Executive Chairman</span> */}
                                    <div className="stars">
                                        <Link to={"#"}><i className="fas fa-star" /></Link>
                                        <Link to={"#"}><i className="fas fa-star" /></Link>
                                        <Link to={"#"}><i className="fas fa-star" /></Link>
                                        <Link to={"#"}><i className="fas fa-star" /></Link>
                                        <Link to={"#"}><i className="fas fa-star" /></Link>
                                    </div>
                                    <p>
                                        "Working with this team was an absolute pleasure. Their dedication to excellence and attention to detail helped us achieve our goals seamlessly. Highly recommend their services!"
                                    </p>
                                    {/* <div className="reviewer">
                                        <img src={process.env.PUBLIC_URL + '/img/reivewer.jpg'} alt="testimonial-img" />
                                    </div> */}
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="swiper-slide">
                                <div className="single-testimonial">
                                    <div className="quote">
                                        <i className="fas fa-quote-right" />
                                    </div>
                                    <h5>Josh Hirani</h5>
                                    {/* <span>Director</span> */}
                                    <div className="stars">
                                        <Link to={"#"}><i className="fas fa-star" /></Link>
                                        <Link to={"#"}><i className="fas fa-star" /></Link>
                                        <Link to={"#"}><i className="fas fa-star" /></Link>
                                        <Link to={"#"}><i className="fas fa-star" /></Link>
                                        <Link to={"#"}><i className="fas fa-star" /></Link>
                                    </div>
                                    <p>
                                        "Their innovative approach and professional service have made a significant impact on our projects. We value their partnership and look forward to continued collaboration."
                                    </p>
                                    {/* <div className="reviewer">
                                        <img src={process.env.PUBLIC_URL + "/img/reivewer-1.jpg"} alt="testimonial-img" />
                                    </div> */}
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="swiper-slide">
                                <div className="single-testimonial">
                                    <div className="quote">
                                        <i className="fas fa-quote-right" />
                                    </div>
                                    <h5>Anand Karki</h5>
                                    {/* <span>Managing Director</span> */}
                                    <div className="stars">
                                        <Link to={"#"}><i className="fas fa-star" /></Link>
                                        <Link to={"#"}><i className="fas fa-star" /></Link>
                                        <Link to={"#"}><i className="fas fa-star" /></Link>
                                        <Link to={"#"}><i className="fas fa-star" /></Link>
                                        <Link to={"#"}><i className="fas fa-star" /></Link>
                                    </div>
                                    <p>
                                        "An excellent experience from start to finish. The team's expertise and support were invaluable, helping us to achieve outstanding results."
                                    </p>
                                    {/* <div className="reviewer">
                                        <img src={process.env.PUBLIC_URL + '/img/reivewer-2.jpg'} alt="testimonial-img" />
                                    </div> */}
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="swiper-slide">
                                <div className="single-testimonial">
                                    <div className="quote">
                                        <i className="fas fa-quote-right" />
                                    </div>
                                    <h5>Rajan Devkota</h5>
                                    {/* <span>CEO</span> */}
                                    <div className="stars">
                                        <Link to={"#"}><i className="fas fa-star" /></Link>
                                        <Link to={"#"}><i className="fas fa-star" /></Link>
                                        <Link to={"#"}><i className="fas fa-star" /></Link>
                                        <Link to={"#"}><i className="fas fa-star" /></Link>
                                        <Link to={"#"}><i className="fas fa-star" /></Link>
                                    </div>
                                    <p>
                                        "Their dedication and professional service have been impressive. They exceeded our expectations and delivered outstanding results."
                                    </p>
                                    {/* <div className="reviewer">
                                        <img src={process.env.PUBLIC_URL + '/img/reivewer-3.jpg'} alt="testimonial-img" />
                                    </div> */}
                                </div>
                            </SwiperSlide>
                        </div>
                    </Swiper>
                    <div className="swiper-button-next" />
                    <div className="swiper-button-prev" />
                </div>
            </section>
        </>
    );
}

export default Testimonial;
