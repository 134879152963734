import React from "react";
import { Link } from "react-router-dom";

function Footer(props) {
  const scrollTop=()=>window.scrollTo({top:0,behavior:"smooth"})
  return (
    <>
      <footer className={props.footerAddclass} >
        <div className="container">
          <div className="footer-top">
            <div className="row">
              <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <Link scrollTo={scrollTop} to={"/"}>
  <img src={process.env.PUBLIC_URL +"/img/logo.png"} alt="FooterImg" width="100" height="30" />
</Link>
                  </div>
                  <p>
                    We are Startup Friendly go-to tech partner. Handover your tech-stress and focus beyond.
                  </p>
                  <ul className="social-media-icons">
                    <li>
                      <a href="https://www.facebook.com/">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.twitter.com/">
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.pinterest.com/">
                        <i className="fab fa-linkedin" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/">
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-lg-3 col-xl-3">
               <div className="footer-widget">
    <h4>Our Services</h4>
    <ul className="footer-menu">
        <li>
            <Link to={`${process.env.PUBLIC_URL}/services`} onClick={scrollTop}>Products & Technology</Link>
        </li>
        <li>
            <Link to={`${process.env.PUBLIC_URL}/services`} onClick={scrollTop}>Team Augmentation</Link>
        </li>
        <li>
            <Link to={`${process.env.PUBLIC_URL}/services`} onClick={scrollTop}>Design</Link>
        </li>
        <li>
            <Link to={`${process.env.PUBLIC_URL}/services`} onClick={scrollTop}>Data & AI</Link>
        </li>
        <li>
            <Link to={`${process.env.PUBLIC_URL}/services`} onClick={scrollTop}>DevOps & Cloud</Link>
                    </li>
                    <li>
            <Link to={`${process.env.PUBLIC_URL}/services`} onClick={scrollTop}>& More</Link>
        </li>
    </ul>
</div>

              </div>
              <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <h4>Quick Links</h4>
                  <ul className="footer-menu">
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/about-us`} onClick={scrollTop}>About Us</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/services`} onClick={scrollTop}>Services</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/projects`} onClick={scrollTop}>Project</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/contact-us`} onClick={scrollTop}>Career</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/services`} onClick={scrollTop}>Contact Us</Link>
                    </li>
                    <li>
<a href="https://calendly.com/dlplatforms/30min/" target="_blank" rel="noopener noreferrer" onClick={scrollTop}>
  Book A Call
</a>                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <h4>Contacts</h4>
                  <div className="number">
                    <div className="num-icon">
                      <i className="fas fa-phone-alt" />
                    </div>
                    <div className="phone">
                      <a href="tel:0457135403">+61 457135403</a>
                      <a href="tel:9749405900">+977 9749405900</a>
                    </div>
                  </div>
                  <div className="office-mail">
                    <div className="mail-icon">
                      <i className="far fa-envelope" />
                    </div>
                    <div className="email">
                      <a href="tell:hi@dlplatforms.com">hi@dlplatforms.com</a>
                      <a href="tell:inquery@dlplatforms.com">inquery@dlplatforms.com</a>
                    </div>
                  </div>
                  <div className="address">
                    <div className="address-icon">
                      <i className="fas fa-map-marker-alt" />
                    </div>
                    <p>Rotty Hill, Sydney</p>
                    <br />
                     <p>Wollert, Melbourne</p>
</div>
                  
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row align-items-center">
              <div className="col-12 col-md-4 col-lg-4 col-xl-5">
                <div className="copy-txt">
                  <span>
                    Copyright 2024 | Design By{" "}
                    <Link to={"#"}>DLPlatforms PTY LTD</Link>
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-8 col-lg-8 col-xl-7">
                <ul className="footer-bottom-menu">
                  <li>
                    <Link to={"#"}>Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to={"#"}>Terms of Use</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
